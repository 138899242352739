.heading {
    padding-left: 3rem;
    padding-right: 3rem;
    font-family: 'Rochester', cursive !important;
    color: #3f51b5;
    font-weight: 900;
  }
  
  @media only screen and (max-width: 600px) {
      .heading {
        padding-left: 0rem;
        padding-right: 0rem;
        text-align: center !important;
        font-size: 3rem;
      }
  }