.footer {
    height: 22rem;
    background-color: #3f51b5;
    color: #ffffff;
    padding-top: 3rem;
    margin: 0em;
}

.footer-brand {
    font-family: 'Rochester', cursive !important;
    color: #ffffff;
    font-weight: 900;
    font-size: 4rem;
    padding-left: 4rem;
}

.footer-contact-title {
    font-family: 'Rochester', cursive !important;
    text-align: right;
    color: #ffffff;
    font-size: 3rem;
    padding-right: 4rem;
    padding-bottom: 0.5rem;
}

.footer-contact {
    text-align: right;
    color: #ffffff;
    font-size: 1rem;
    padding-right: 4rem;
}

.footer-contact-line {
    padding-top: 1rem;
    padding-right: 4rem;
}

.footer-contact-social-icons {
    display: inline;
    padding-right: 4rem;
}

.copyright-notice {
    padding-top: 3rem;
    font-size: 1rem;
}

.credit-notice {
    padding-top: 1rem;
    font-size: 1rem;
}

@media only screen and (max-width: 600px) {
    .footer {
        height: 30rem
    }

    .footer-contact-title {
        font-family: 'Rochester', cursive !important;
        text-align: center;
        color: #ffffff;
        font-size: 3rem;
        padding-right: 0rem;
        padding-bottom: 0.5rem;
    }

    .footer-contact {
        padding-top: 1rem;
        padding-right: 0rem;
        text-align: center;
    }

    .footer-brand {
        display: none;
        padding-left: 0rem;
        text-align: center;
    }

    .footer-contact-line {
        padding-right: 0rem;
    }

    .footer-contact-social-icons {
        font-size: 2rem;
        text-align: center;
        padding-right: 0rem;
        display: inline-block;
        justify-content: center !important;
    }
}