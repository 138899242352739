.portrait-photo{
    width:312px;
    border-radius: 50%;
}

.img-center {
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.about-text {
    padding-top: 5rem;
    text-align: center;
    font-weight: normal;
    font-size: 1.2rem;
    padding-right: 3rem;
    line-height: 2rem;
} 

.skill-tag {
    color: #3f51b5 !important;
    border-color: #3f51b5 !important;
    margin: 0.5rem !important;
}

.skill-tags {
    text-align: center;
    padding-right: 3rem;
    padding-top: 2rem;
}

@media only screen and (max-width: 600px) {
    .portrait-photo{
        padding-top: 2rem;
    }

    .about-text {
        text-align: center;
        padding-right: 0rem;
    } 

    .skill-tags {
        text-align: center;
        padding-right: 0rem;
    }
}